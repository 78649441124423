import React, { useContext, useEffect } from 'react';
import { Slide, Snackbar, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AppDataContext from './AppContext';
import parser from 'html-react-parser'

const useStyle = makeStyles(theme => ({
    customSnackBar: {
    boxShadow: '0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a !important',
    borderLeftWidth: '4px',
    borderRightWidth: '2px',
    borderTopWidth: '2px',
    borderBottomWidth: '2px',
    borderColor: 'rgb(103, 232, 249)',
    borderStyle: 'solid',
    borderRadius: '5px',
    '& .MuiPaper-root': {
        background: '#fff',
        color:'#374151',
    },
    },
}))

export default function SnackNotifier() {
    const { snackMessages, removeSnackMessages } = useContext(AppDataContext);
    const classes = useStyle();

    function getBottom(index) {
        return (index) ? { bottom: index * (24 * 3), marginBottom: 8 } : {};
    }

    const handleClose = (event, reason, index) => {
        if (reason === 'clickaway') {
            return;
        }
        removeSnackMessages(index);
    }

    return snackMessages.map((alert, index) => {
        if (alert?.type === 'actionToast') {
            return (
                <Snackbar key={index} open={alert.open} className={classes.customSnackBar}
                autoHideDuration={(alert.hideDuration) ? alert.hideDuration : 4000}
                anchorOrigin={(alert.anchorOrigin) ? alert.anchorOrigin : { vertical: 'bottom', horizontal: 'right' }}
                onClose={(e, r) => handleClose(e, r, index)}
                //style={getBottom(index)}
                TransitionComponent={Slide}>
                    <Alert icon={alert.icon} onClose={(e, r) => handleClose(e, r, index)} severity={alert.severity}>
                        <div>
                            <span >{parser(alert.message.title)}</span>
                            <div>
                                {alert.message.onClick && <a style={{'cursor' : 'pointer'}} onClick={() => {
                                    alert.message.onClick();
                                    handleClose({}, "close", index);
                                }}>{alert.message.buttonLabel}</a>
                                }
                                { parser(alert.message.subtitle)}</div>
                        </div>
                    </Alert>
                </Snackbar>)
        }
        else {
            return <Snackbar key={index} open={alert.open}
                autoHideDuration={(alert.hideDuration) ? alert.hideDuration : 4000}
                anchorOrigin={(alert.anchorOrigin) ? alert.anchorOrigin : { vertical: 'bottom', horizontal: 'right' }}
                onClose={(e, r) => handleClose(e, r, index)}
                style={getBottom(index)}
                TransitionComponent={Slide}
            >
                <Alert icon={alert.icon} onClose={(e, r) => handleClose(e, r, index)} severity={alert.severity}>{alert.message}</Alert>
            </Snackbar>
        }
    }
    )
}
    const ToastComponent = ({ alert, index, theme, handleClose, style }) => {
        let { title, subtitle, onClick, buttonLabel } = alert.message;

        const  getBottomcss =(index) => {
            return (index) ? { bottom: index * (24 * 3), marginBottom: 8 } : {};
        }

        return (
            <Snackbar key={index} open={alert.open}
                autoHideDuration={(alert.hideDuration) ? alert.hideDuration : 4000}
                anchorOrigin={(alert.anchorOrigin) ? alert.anchorOrigin : { vertical: 'bottom', horizontal: 'right' }}
                onClose={(e, r) => handleClose(e, r, index)}
                style={getBottomcss(index)}
                TransitionComponent={Slide}>
                <Alert icon={alert.icon} onClose={(e, r) => handleClose(e, r, index)} severity={alert.severity}>
                </Alert>
                <div>
                    <span className="mb-1 text-sm font-semibold text-nba-blue">{parser(title)}</span>
                    <div className="mb-2 text-sm font-normal">{parser(subtitle)}</div>
                    <div>
                        {onClick && <button onClick={() => {
                            buttonLabel();
                            handleClose({}, "close", index);
                        }}>{buttonLabel}</button>
                        }
                    </div>
                </div>
            </Snackbar>
        )
    }
