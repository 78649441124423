const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'h:mm A';
const TIME_FORMAT_24 = 'HH:mm';
const DATE_TIME_FORMAT = 'MM/DD/YYYY, h:mm A';
const DATE_TIME_FORMAT_24 = 'MM/DD/YYYY, HH:mm';
const DATE_TIME_FORMAT_24_WITH_TIMEZONE = 'YYYY-MM-DDTHH:mm';
const DAY_DATE_FORMAT = 'dddd, MM/DD/YYYY';
const MONTH_YEAR_FORMAT = 'MM/YYYY';
const MON_YEAR_FORMAT = 'MMM YYYY';
const YEAR_DATE_FORMAT = 'YYYY-MM-DD';
const MONTH_DATE_YEAR_FORMAT = 'MM-DD-YY';
const info = {
    DateFormat: DATE_FORMAT,
    TimeFormat: TIME_FORMAT,
    TimeFormat24: TIME_FORMAT_24,
    DateTimeFormat: DATE_TIME_FORMAT,
    DateTimeFormat24: DATE_TIME_FORMAT_24,
    DayWithDateFormat: DAY_DATE_FORMAT,
    YearDateFormat: YEAR_DATE_FORMAT,
    MonthWithYearFormat: MONTH_YEAR_FORMAT,
    MonWithYearFormat: MON_YEAR_FORMAT,
    DateTimeFormat24WithTimezone: DATE_TIME_FORMAT_24_WITH_TIMEZONE,
    MonthDateYearFormat: MONTH_DATE_YEAR_FORMAT
}

class CultureInfo {
    static GetCultureInfo() {
        return info;
    }
}

export default CultureInfo;