const AppConstants = {
  //size in MB
  MAX_PROFILE_PHOTO_FILE_SIZE: "10",
  MAX_PROFILE_VIDEO_FILE_SIZE: "25",
  MAX_SCHEDULE_ATTACHMENT_FILE: "10",
  NA: "N/A",

  PROFILECOMPLETENESS_VALIDATION_MESSAGE: "(Your profile should complete atlease 60% for submition)",
  EDIT_SECTION_TITLE: {
    ContactDetails: "Contact Details",
    Events: "Events",
    //CurrentCoachingExp: "Current Coaching Experience",
    UpcomingSchedule: "Upcoming Schedule",
    Education: "Education",
    Employment: "Employment",
    Experience: "Officiating",
    GeneralInformation: "General Information",
    SelfAssessment: "Officiating Goals/Self-Assessment",
    Quiz: "Lesson Plan Analysis"
  },
  PROFILE_SECTION_TITLE: {
    ContactDetails: "Contact Details",
    Events: "Events",
    //CurrentCoachingExp: "Current Coaching Experience",
    UpcomingSchedule: "Schedule",
    Education: "Education",
    Employment: "Employment",
    Experience: "Officiating",
    GeneralInfo: "General Info",
    SelfAssessment: "Self Assessment",
    Quiz: "Lesson Plan Analysis",
    AboutUs: "About Us"
  },
  DASHBOARD_SECTION_TITLE: {
    AllProfiles: "All Prospects",
    RecentlyUpdatedProfiles: "Recently Modified",
    NoActivityProfiles: "No Recent Activity",
    NewCreated: "Recently Added",
    NoPrimaryScout: "No Primary Scout",
    NoEvaluation: "No Evaluation by Scout",
    AllEvaluated: "All Evaluated",
    ProspectsWatchList: "Prospects Watchlist",
    Archived: "Archived Profiles"
  },
  DASHBOARD_SECTION_ID: {
    All: 0,
    AllEvaluated: 1,
    NoPrimaryScout: 2,
    NoEvaluation: 3,
    NewCreated: 4,
    RecentlyUpdated: 5,
    Archived: 6,
    ProspectsWatchList: 7
  },
  MAP_FILTER_CATEGORY: [
    { value: 0, text: 'Prospects' },
    { value: 1, text: 'Events' },
    { value: 2, text: "Prospects by PoolId's" },
  ],
  QUIZ_STATUS_NAME: {
    IN_PROGRESS: "In Progress",
    TO_BE_DONE: "To be done",
    DONE: "Done"
  },
  APPROVAL_STATUS:
    [{ value: 0, text: 'Awaiting Approval' },
    { value: 1, text: 'Approved' },
    { value: 2, text: "Rejected" },
    ],
  IncompletedFields: {
    Country: "country",
    AboutUsSource: "aboutUsSource",

  },
  COLORS : ['#fc4c4c', '#8f4ba0', '#00a2e2', '#fecc15', '#00b282', '#FF8042', "#1AFF33", "#999933", "#FF3380", "#CCCC00", "#66E64D", "#4D80CC", "#FF4D4D", "#99E6E6", "#6666FF", "#E6FF80"],
  ABOUT_US_CONFIG_KEY : 'HeardAboutusInfo',
};

export default AppConstants;
