const APIURLConstants = {
    PING: "/coach/Ping",
    // Authentication Controller Route
    AUTHENTICATE_USER: "/auth/login",
    // AUTHENTICATE_ADMIN_USER: "/user/validateadminuser",
    REGISTER_USER: '/auth/register-user',
    CHANGE_PASSWORD: "/auth/change-password",
    SEND_CHANGE_EMAIL_OTP: "/auth/generate-change-email-otp",
    CHANGE_EMAIL: "/auth/change-email",
    FORGOT_PASSWORD: "/auth/forgot-password-otp",
    RESET_PASSWORD: "/auth/reset-password",
    VALIDATE_PASSWORD_TOKEN: (token) => `/auth/validate-password-token/${token}`,
    VERIFY_EMAIL: "/auth/verify-email",
    RESEND_EMAIL_VERIFICATION: "/auth/resend-email-verification",
    GET_PERMISSIONS_BY_ROLE: '/rolepermission/get-permissions-by-role',
    // User Controller Route
    GET_PROFILE_DETAILS: "/user/contact-detail",
    GET_USER_OFFICIATING_SCHEDULE: "/user/officiating-schedule",
    GET_USER_EDUCATION_DETAIL: "/user/education-detail",
    GET_USER_EMPLOYMENT_HISTORY: "/user/employment-history",
    GET_USER_OFFICIATING_EXPERIENCE: "/user/officiating-experience",
    GET_USER_OFFICIATING_EVENTS: "/user/officiating-events",
    GET_USER_GENERAL_INFO: "/user/general-info",
    GET_USER_SELF_ASSESSMENT: "/user/self-assessment",
    GET_USER_SOCIAL_MEDIA: "/user/social-media",
    GET_USER_MEDIA_PROFILES: "/user/media-profiles",
    GET_SCHEDULE_ATTACHMENTS: "/schedule/attachments",
    SAVE_SCHEDULE_ATTACHMENT: "/schedule/save-attachment",
    DELETE_SCHEDULE_ATTACHMENT: "/schedule/delete-attachment",
    GET_DYNAMIC_URL_ATTACHMENT: '/schedule/attachment-url',

    SIGNOUT: "nbaofficials/SignOut",
    SINGNUP_COACH: "/user/Save",
    VALIDATE_INVITE_TOKEN: "/partner/validate-invitation",
    GET_JWTOKEN: "/masterdata/getjwtoken",
    ACCEPT_TERMS_CONDITIONS: "/user/accepttnc",
    GET_SITEKEY: "/user/getrecaptchasitekey",
    GET_MASTER_DATA: '/masterdata/getall',
    GET_PROFILE_MASTER_DATA: '/masterdata/getprofilemasterdata',
    GET_GUEST_MASTER_DATA: '/masterdata/guest-master-data',
    GET_ADMIN_USERS: '/masterdata/getAdminUsers',
    GET_ADMIN_SCOUT_USERS: '/masterdata/getAdminScoutUsers',
    GET_MASTER_DATA_BY_NAME: '/masterdata/get',
    GET_MASTER_DATA_SEASONDATES: '/masterdata/get-season-dates',
    GET_ALL_POOLS: '/masterdata/get-pools',
    GET_ALL_USERS: '/masterdata/getAllUsers',
    SAVE_CONTACT_INFO: '/user/SaveContactInfo',
    GET_BASIC_CONTACT_INFO: '/user/user-contact-detail-media',
    SAVE_PROFILE_PICTURE: '/user/saveprofilepicture',
    SAVE_FULL_PICTURE: '/user/SaveFullPicture',
    SAVE_PROFILE_VIDEO: '/user/saveprofilevideo',
    DELETE_PROFILE_PICTURE: '/user/deleteprofilepicture',
    DELETE_FULL_PICTURE: '/user/DeletefullPicture',
    DELETE_PROFILE_VIDEO: '/user/deleteprofilevideo',
    GET_PROFILE_PICTURE_URL: '/user/GetProfilePictureUrl',
    GET_PROFILE_VIDEO_URL: '/user/GetProfileVideoUrl',
    SAVE_PERSONAL_INFO: '/user/SavePersonalInfo',
    SAVE_SELF_ASSESSMENT: '/user/SaveSelfAssessmentInfo',
    SAVE_GENERAL_INFO: '/user/SaveGeneralInfo',
    SAVE_MANDATORY_CONTACT_INFO: '/user/save-mandaory-contactinfo',
    CHECK_EMAIL_EXISTS: '/auth/check-email-profile',
    SAVE_SCHEDULE_INFO: 'schedule/saveall',
    SAVE_EDUCATION_DETAILS: 'education/saveall',
    SAVE_EMPLOYMENT_HISTORY_DETAILS: 'Employment/saveall',
    SAVE_EXPERIENCE_DETAILS: 'experience/saveall',
    DELETE_SCHEDULE_INFO: 'schedule/delete',
    DELETE_EDUCATION_DETAILS: 'education/delete',
    DELETE_EMPLOYMENT_HISTORY_DETAILS: 'employment/delete',
    DELETE_EXPERIENCE_DETAILS: 'experience/delete',
    UPDATE_NO_EXPERIENCE: 'experience/update-noexperience',
    GET_ALL_THREADS: '/messagecenter/getallthreads',
    ADD_NEW_THREAD: '/messagecenter/insertthread',
    REPLY_THREAD: '/messagecenter/updatepost',
    UpdateThreadStatus: 'messagecenter/updateThreadStatus',
    GET_THREAD_DETAILS: '/messagecenter/getallthreaddetails',
    GET_EXPORT_EVENT_PDF: '/event/export-event',
    GET_CANDIDATE_CAMPS: '/eventcalendar/get-candidate-camps',
    YAO_EXPORT_EVENTS: '/eventcalendar/exportevents',
    YAO_IMPORT_EVENTS: '/eventcalendar/uploadevents',
    GET_EVENTS_BY_DATE: '/eventcalendar/geteventsbydate',
    //YAO_SAVE_EVENT: '/eventcalendar/saveevent',
    YAO_SAVE_EVENT_TASK: '/eventcalendar/save-task',
    YAO_SAVE_EVENT_MEETING: '/eventcalendar/save-meeting',
    YAO_SAVE_EVENT_ATTENDANCE: '/eventcalendar/save-attendance',
    YAO_SAVE_OFFICIATING_EVENT: '/eventcalendar/save-officiating-event',
    YAO_SAVE_CLINIC_EVENT: '/eventcalendar/save-clinic-event',
    YAO_SAVE_POST_CLINIC_EVENT: '/eventcalendar/save-post-clinic-event',
    YAO_SAVE_CANDIDATE_CAMP_EVENT: '/eventcalendar/save-candidate-camp',
    YAO_SAVE_CANDIDATE_CAMP_EVENT_ROASTER: '/eventcalendar/save-candidate-camp-roaster',
    YAO_SAVE_CANDIDATE_CAMP_EVENT_INVITATION_RESPONSE: '/eventcalendar/save-invitation-response',
    //YAO_SAVE_EVENT_MEDIA: '/eventcalendar/saveeventmedia',
    YAO_DELETE_EVENT_MEDIA: '/eventcalendar/deleteeventmedia',
    YAO_GET_USERS_BY_EVENT: 'eventcalendar/GetUsersByEvent',
    YAO_DELETE_EVENT: '/eventcalendar/deleteevent',
    SAVE_ASSIGNOR_INFO: '/assignor/addassignor',
    UPDATE_ASSIGNOR_INFO: '/assignor/updateassignor',
    DELETE_ASSIGNOR_INFO: '/assignor/deleteassignor',
    GET_ALL_ASSIGNORS: '/assignor/getallassignors',
    SAVE_EVENTS_DETAILS: 'officiatingEvents/saveall',
    GET_ASSIGNOR_INFO: '/assignor/getassignorsbyid',
    GET_ASSIGNORS_FILTER: '/assignor/getassignorsbyfilter',
    GET_UPDATED_THREADS: '/messagecenter/getInsertedOrUpdatedThreads',
    UPDATE_NOTIFICATIONS: '/messagecenter/updateNotificationFlag',
    GET_ALL_PROSPECTS: '/prospects/getall',
    GET_ALL_PROSPECTS_HEADSHOTS: '/prospects/get-all-headshots',
    GET_PROSPECT_PROFILE: '/prospects/GetProspectByUserName',
    GET_CATEGORY_LIST: '/messagecenter/GetCategories',
    ADD_UPDATE_CATEGORY: '/messagecenter/addupdatecategory',
    GET_ACTIVE_CATEGORY_LIST: '/messagecenter/active-categories',
    // GET_PROSPECT_PROFILE: '/prospects/GetProspectByUserName?userName=',
    GET_PROSPECT_PDF: '/prospects/GetProspectPdf',
    GET_HOMEPAGE_DATA: '/cmsdocument/GetHomeContent',
    GET_DASHBOARD_DATA: '/dashboard/details',
    GET_NBA_QUIZ: '/home/getquizcontent',
    GET_DASHBOARD_STATES: '/dashboard/dashboardStateDetail',
    GET_DASHBOARD_EXPERIENCE: '/dashboard/dashboardExperienceDetail',
    GET_DASHBOARD_PROFILE_COUNT_BY_REGION: '/dashboard/profile-count-by-region',
    GET_DASHBOARD_PROFILE_COUNT_BY_SCOUT: '/dashboard/profile-count-by-scout',
    GET_DASHBOARD_PROFILE_COUNT_BY_NP: '/dashboard/profile-count-by-np',
    GET_DASHBOARD_PROFILE_COUNT_BY_YEAR: '/dashboard/profile-count-by-year',
    GET_DASHBOARD_PROFILE_COUNT_BY_EVALUATIONS: '/dashboard/profile-count-by-evaluation',
    GET_DASHBOARD_STATEWISE_ATTENDANCE_BY_SCOUTS: '/dashboard/statewise-attendance-by-scouts',
    GET_DASHBOARD_EVENT_COUNT_BY_SCOUTS: '/dashboard/event-count-by-scouts',
    SEASON_CYCLE_REPORT_EXCEL: '/dashboard/season-cycle-report-excel',
    SEASON_CYCLE_REPORT_PDF: '/dashboard/season-cycle-report-pdf',
    STATE_WIDE_ATTENDANCE_EXCEL: '/dashboard/state-wide-attendance-excel',

    UPDATE_LINK_OFFICIAL: 'prospects/updateLinkOfficial',
    GET_ALL_STATES: '/masterdata/get-geo-json',
    GET_ALL_WATCHLIST: '/watchlist/GetAllWatchListByScout',
    GET_ALL_WATCHLISTS: '/watchlist/all',
    SAVE_WATCHLIST: '/watchlist/CreateWatchList',
    UPDATE_WATCHLIST: '/watchlist/UpdateWatchList',
    DELETE_WATCHLIST: '/watchlist/DeleteWatchList',
    GET_EVALUATION_CATEGORY_LIST: '/evaluation/getcategories',
    INSERT_EVALUATION_CATEGORY: '/evaluation/insertcategory',
    UPDATE_EVALUATION_CATEGORY: '/evaluation/updatecategory',
    INSERT_EVALUATION_SUBCATEGORY: '/evaluation/insertsubcategory',
    UPDATE_EVALUATION_SUBCATEGORY: '/evaluation/updatesubcategory',
    UPDATE_EVALUATION_CATEGORY_STATUS: '/evaluation/UpdateCategoryStatus',
    UPDATE_EVALUATION_SUBCATEGORY_STATUS: '/evaluation/UpdateSubCategoryStatus',
    INSERT_EVALUATION_COMPETENCY: '/evaluation/insertComptenecy',
    UPDATE_EVALUATION_COMPETENCY: '/evaluation/updateComptenecy',
    GET_EVALUATION_COMPETENCY: '/evaluation/getCompetencies',
    UPDATE_COMPETENCY_STATUS: '/evaluation/UpdateComptenecyStatus',
    GET_DYNAMIC_PAGE_CMS: '/cmsdocument/getdynamicpagecontent',
    GET_EVALUATION_EVENTS: '/evaluation/getevents',
    SAVE_EVALUATION_RATINGS: '/evaluation/insertEvaluation',
    UPDATE_EVALUATION_RATINGS: '/evaluation/updateEvaluation',
    EVALUATION_LIST: '/evaluation/listEvaluations',
    DELETE_EVALUATION: '/evaluation/DeleteEvaluation',
    GET_MASTER_DATA_BY_GROUP: '/masterdata/get',
    INSERT_EVALUATION_EVENT: '/evaluation/insertEvent',
    UPDATE_EVALUATION_EVENT: '/evaluation/updateEvent',
    GET_USER_SUMMARY: "/user/getUserSummary",
    GET_CMS_DYNAMICMENU: '/cmsdocument/mega-menu',
    // GET_CMS_DYNAMICMENU: '/cmsdocument/getDynamicPageContent',
    GET_CMS_CONTENT_BY_ID: '/cmsdocument/GetPageContentById',
    GET_CMS_CONTENT_BY_SLUG: '/cmsdocument/content-by-slug',
    GET_REFSTATS_DATA: '/masterdata/GetRefStatRefereeList',
    GET_QUIZ_LIST: '/cmsdocument/GetAllQuizes',
    GET_MY_QUIZES: 'cmsdocument/GetMyQuizes',
    SYNC_QUIZ_LIST: '/cmsdocument/SyncCmsDocuments',
    GET_QUIZ_CONTENT_BY_ID: '/cmsdocument/GetAllQuizContent',
    GET_PUBLIC_QUIZES: '/cmsdocument/GetPublicQuizes',
    UPDATE_DOCUMENT_ACCESS: '/cmsdocument/update-access',
    // SAVE_QUIZ: '/quizhistory/savequiz',
    SAVE_QUESTION_ANSWER: '/quizhistory/savequizanswer',
    GET_QUIZ_USER_SCORE: '/quizhistory/GetQuizUsersAndScore?quizUid=',
    GET_USER_SCORES: '', // '/quizhistory/getuserscores?officialUID=',
    GET_QUIZ_USER_ACCESS: '', // '/quizhistory/GetQuizUserAccess?quizUid=',
    Delete_DOCUMENT_ACCESS: '/cmsdocument/DeleteDocumentAccess',
    GET_LESSON_PLAN_DASHBOARD_DATA: '/lessonplan/dashboard',
    GET_LESSON_PLAN_LIST: '/lessonplan/list',
    GET_PUBLIC_LESSON_PLAN_LIST: '/lessonplan/public',
    GET_LESSON_PLAN_DETAILS: '/lessonplan/getlessonplandetails',
    LESSON_PLAN_CREATE: '/lessonplan/insert',
    LESSON_PLAN_UPDATE: '/lessonplan/update',
    LESSON_PLAN_UPDATE_STATUS: '/lessonplan/updateStatus',
    LESSON_PLAN_USER_ACCESS: '/lessonplan/useraccess',
    LESSON_PLAN_DELETE_USER_ACCESS: `/lessonplan/deleteaccess`,
    LESSON_PLAN_UPDATE_USER_ACCESS: `/lessonplan/update-access`,
    LESSON_PLAN_UPDATE_ACCESS_LEVEL: `/lessonplan/update-access-level`,
    GET_QUIZ_CONTENT: `/lessonplan/quizcontent`,
    GET_MY_LESSONS: `/lessonplan/mylessons`,
    LESSON_PLAN_USERS_AND_SCORES_GET: "/lessonplan/usersandscores",
    SAVE_QUIZ_ANSWER: `/lessonplan/savequizanswer`,
    SAVE_QUIZ: `/lessonplan/savequiz`,
    QUESTIONS_BANK_LIST: '/questionbank/list',
    SHARE_EVENT: '/eventcalendar/shareEvent',
    YAO_GET_EVENT_BY_ID: '/event/get',
    YAO_GET_EVENT_BY_SLUG: '/event/getEventBySlug',
    YAO_SAVE_EVENT_USER: '/event/register-user', // When user is new and not logged in, registering thru registration dialog box
    YAO_SAVE_EVENT_OFFICIALS: '/event/register-user', // When user is logged in
    YAO_SAVE_GUEST_USER: '/event/register-guest-user',  //when user is guest   
    YAO_GET_PUBLIC_EVENTS_UPCOMING: '/event/upcoming-clinic-events',
    YAO_GET_PUBLIC_EVENTS_PAST: '/event/past-clinic-events',
    YAO_GET_ORGANIZATIONS_BY_AFFILIATION: '/eventcalendar/get-organization-by-affiliation',
    YAO_GET_CANDIDATE_CAMP_ROASTER_BY_CAMP_ID: '/eventcalendar/get-candidate-camp-roaster',
    ORG_MEMBER_SEND_INVITE: '/partner/send-invitation',
    ORG_MEMBER_GET_EVENTS: '/partner/getEventsByPartnerOrganization',
    ORG_MEMBER_YAO_SHARE_EVENT: '/partner/shareEvent',
    ORG_MEMBER_YAO_SAVE_EVENT: '/partner/savePartnerEvent',
    ORG_MEMBER_YAO_DELETE_EVENT_MEDIA: '/partner/deleteeventmedia',
    GET_NOTIFICATION_LIST: '/eventInvitation/geteventinvitations',
    GET_EVENTNOTIFICATION_BY_ID: '/eventInvitation/getnotificationbyid',
    SAVE_EVENT_NOTIFICATION: 'eventInvitation/insupdeventinvitation',
    DELETE_NOTIFICATION: '/eventInvitation/deletenotification',
    SAVE_NOTIFICATION_PARTICIPANTS: '/eventInvitation/savenotificationparticipants',
    DELETE_NOTIFICATION_PARTICIPANTS: '/eventInvitation/deletenotificationparticipants',
    SEND_NOTIFICATION: '/eventInvitation/sendNotification',
    GET_CANDIDATE_CAMP_ROSTER_STATUS: '/eventcalendar/get-candidate-camp-decision',
    GET_AFFILIATION_NETWORK: '/partner/getaffiliationnetworks',
    GET_PARTNER_ORGANIZATION: '/partner/getpartnerorganization',
    SAVE_CANDIDATE_CAMP_POSTEVENT: '/eventInvitation/save-postevent-ranking',
    GET_PROSPECT_LIST_INVITATION: '/prospects/for-invitation',
    GET_PERTNER_USER_DETAILS: '/partner/details',
    UPDATE_PRIMARY_SCOUT: '/prospects/updatePrimaryScout',
    LINK_POOL: '/prospects/linkPool',
    POOL_HISTORY: '/prospects/pool-history',
    SAVE_EVENT_ISCOMPLETED: '/eventcalendar/save-iscompleted',
    SAVE_EVENT_APPROVAL_STATUS: '/eventcalendar/save-approval-status',
    GET_USER_DETAILS: '/Auth/details',
    SAVE_POOL: '/masterdata/update-pool',
    SAVE_POOL_DASHBOARD_STATUS: '/masterdata/update-pool-dashboard-status',
    CONTACTUS_SUBMIT: '/contactus/sendquerytoadmin',
    LESSON_PLAN_FILTER_USERS: '/lessonplan/search-users-for-assignment',
    GET_ROLES: '/user/getroles',
    GET_HEAD_SHOT: `/prospects/GetHeadshotURL`,
    GET_PROFILE_COMPLETENESS: '/user/get-profile-completeness',
    GET_LIST_NOPROSPECT: '/prospects/list-noprospect',
    DELETE_NOPROSPECT: '/prospects/delete-noprospect',
    EXCEL_EXPORT_NOPROSPECT_LIST: '/prospects/export-noprospect',
    INSERT_PROSPECTS_LIST: '/prospects/update-noprospect',
    DELETE_PROFILE: '/prospects/delete-profile',
    SEND_REMINDER_EMAIL: '/user/profile-reminder-email',
    GET_INCOMPLETE_PROFILES: '/user/profile-incomplete-profile',
    GET_MS_COGNITIVE_SERVICE_TOKEN: '/evaluation/get-speech-text-token',
    RESTORE_PROFILE: '/prospects/restore-profile',
    EXCEL_EXPORT_EVENT_LIST: '/evaluation/export-evaluation-events',
    GUEST_PROFILE_REGISTRATION_REQUEST: '/event/send-bulk-email-to-event-guest-participants',
    GUEST_PROFILE_REGISTRATION_REQUEST_SINGLE: '/event/send-email-to-guest-participant',
    GET_GUEST_PROFILE_REQUEST: '/event/get-guest-profile-request',
    GET_SITEKEY: "/user/getrecaptchasitekey",
    GET_FAQS: "/contactus/get-faq",
    INSERT_EVALUATION_TANGIBLE: '/evaluation/insertTangible',
    UPDATE_EVALUATION_TANGIBLE: '/evaluation/updateTangible',
    GET_EVALUATION_TANGIBLES: '/evaluation/getTangibles',
    SAVE_ABOUT_US_INFO: '/user/save-aboutus-info',
    GET_ABOUT_US_INFO: '/user/about-us-info',

    //EventDashboard
    GET_EVENT_DASHBOARD_DATA: '/eventdashboard/clinic-event-data',
    GET_ALL_CLINIC_EVENT_CATEGORY: '/masterdata/get-clinicevent-category',
    SAVE_CLINIC_EVENT_CATEGORY: '/masterdata/save-clinicevent-category',
    GET_EVENT_DASHBOARD_REGISTERED_USERS: '/eventdashboard/clinic-event-registrations',
    GET_EVENT_CLINICIAL: '/masterdata/get-eventclinician',
    GET_ALL_EVENT_CLINICIAL: '/masterdata/get-alleventclinician',
    SAVE_EVENT_CLINICIAL: '/masterdata/save-clinician',
    GET_EVENTS_BY_REGIONAL_OFFICE: '/eventdashboard/event-region-data',
    GET_EVENTS_BY_COUNTRY: '/eventdashboard/event-country-data',
    GET_REGIONAL_OFFICE: '/masterdata/get-regionaloffice',
    GET_CLINICIAL_DATA: '/eventdashboard/event-clinician-data',

    //UserRoles
    GET_ALL_ASMS_USERS: '/user/get-users-for-role-assignment',
    UPDATE_USER_ROLE: '/user/update-user-role',

    //permissions
    GET_PERMISSION_LIST: '/permission/get-permission-list',
    SAVE_PERMISSION_BY_ROLE: '/rolePermission/save-permissions-by-role',
    SAVE_APPCONFIG: '/masterdata/update-appconfig',
    GET_APPCONFIG: '/masterdata/get-appconfig-value',
    GET_EVENT_COUNT_WITH_INCOMPLETE_DATA :'/eventdashboard/getEventCountWithIncompleteData'
};

export default APIURLConstants;