import { authHeader, handleResponse, handleError } from '../helper';
import APIHelper from "../common/ApiHelper";
import APIURLConstants from "../common/ApiURLConstants";

export const eventDashboardService = {
    getEventDashboardData,
    getRegisteredUserData,
    getEventsByRegionalOffice, 
    getEventCountByCountry,
    getEventClinicialData,
    getEventCountWithIncompleteData
}

function getEventDashboardData(data) {
    return APIHelper.post(APIURLConstants.GET_EVENT_DASHBOARD_DATA, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getRegisteredUserData(data) {
    return APIHelper.post(APIURLConstants.GET_EVENT_DASHBOARD_REGISTERED_USERS, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getEventsByRegionalOffice(data) {
    return APIHelper.post(APIURLConstants.GET_EVENTS_BY_REGIONAL_OFFICE, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getEventCountByCountry(data) {
    return APIHelper.post(APIURLConstants.GET_EVENTS_BY_COUNTRY, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getEventClinicialData(data) {
    return APIHelper.post(APIURLConstants.GET_CLINICIAL_DATA, data, authHeader())
        //.then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // currentUserSubject.next(user);
            return data;
        }).catch(handleError);
}

function getEventCountWithIncompleteData() {
    return APIHelper.get(APIURLConstants.GET_EVENT_COUNT_WITH_INCOMPLETE_DATA, authHeader())
        .then(data => {
            return data;
        }).catch(handleError);
}