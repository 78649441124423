import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import CKEditor from 'ckeditor4-react';
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import { Editor } from '@tinymce/tinymce-react';
//import Editor from 'ckeditor5-custom-build/build/ckeditor';

const useStyles = makeStyles((theme) => ({
    grid: {
        padding: theme.spacing(1),
        alignSelf: "center",
    },
    errorMessage: { color: 'red', paddingLeft: '2px' },
    demoEditor: {
        height: props => props.height,
        border: '1px solid #ddd'
    },
}));


const HTMLEditor = (props) => {
    //const editorRef = useRef(null);
    const height = (props.height !== undefined  ? props.height : '350px') + '!important'
    const classes = useStyles({height});
    const [value, setValue] = useState(EditorState.createEmpty());

    useEffect(() => {
        let _val = props.value || "";
        const contentBlock = htmlToDraft(_val ? _val : "");
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setValue(editorState);
        }

    }, [props.value]);

    const onChange = (content) => {
        setValue(content);

    }
    const handleOnBlur = (event, editorState) => {

        let data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const contentBlock = htmlToDraft(data);
        if (contentBlock.contentBlocks && contentBlock.contentBlocks.length === 1) {
            let length = contentBlock.contentBlocks[0].getLength();
            if (length === 0 || (contentBlock.contentBlocks[0].getText().trim().length === 0)) {
                data = "";
            }
        }
        let e = { target: { name: props.name, value: data } };
        props.onChange(e);
        //props.handleUpdateNote(data);
    };
    return (
        <Grid container>
            <Grid item md={12} xs={12} className={classes.grid}>
                {/* <CKEditor
                    data={value}
                    config={{
                        removeButtons: 'Subscript,Superscript',
                        extraAllowedContent: '*{*};*(*);span{!color}'
                    }}

                    onChange={onChange}
                /> */}
                {/* <CKEditor
                    className='ck-content'
                    editor={Editor}
                    config={{
                        image: {
                            upload: ['jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff']
                        }
                    }}
                    data={value}
                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log({ event, editor, data });
                        onChange(event, editor)
                    }}
                    onBlur={editor => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={editor => {
                        console.log('Focus.', editor);
                    }}

                /> */}
                {/* <Editor
                    //apiKey='qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'
                    //onInit={(evt, editor) => editorRef.current = editor}
                    onEditorChange={onChange}
                    value={value}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'image',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help' | 'image',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                /> */}
                <Editor
                    editorState={value}
                    readOnly={props.disabled}
                    wrapperClassName="demo-wrapper"
                    editorClassName={classes.demoEditor}
                    toolbarClassName="toolbar-class"
                    spellCheck={'true'}
                    toolbarHidden={props.hideToolbar}
                    preserveSelectionOnBlur={true}
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                        inline: { inDropdown: false },
                        //list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        history: { inDropdown: true },
                        image: {
                            urlEnabled: true,
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            previewImage: true,
                        },
                        fontFamily: {
                            options: ['Arial', 'Georgia', 'Impact', 'Roboto', 'Tahoma', 'Times New Roman', 'Verdana'],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                    }}
                    onEditorStateChange={onChange}
                    onBlur={handleOnBlur}
                />
                {props.error ? <p className={classes.errorMessage}>
                    {props.helperText}</p> : null}
            </Grid>
        </Grid>
    );
};

export default HTMLEditor;